import React from "react";
import logoImg from './img/logo.svg'
import BorrowHistory from '../borrow-history'
import copyImg from './img/copy.svg'
import shareImg from './img/share.svg'
import './style.scss'

const list = [
  { id: 1, name: 'Existing cross-chain amount', laber: "ERC 20", value: "907,278,328.61", share: false },
  { id: 2, name: 'BTC contract address', laber: "", value: "907,278,328.61", share: true },
  { id: 3, name: 'SOLANA contract address', laber: "", value: "907,278,328.61", share: true }
]
const TokenInfo = () => {
  return (
    <div className="tokenInfo">
      <div className="current-price">
        <div className="name">
          <div className="laber">Current price</div>
          <div className="value">$0.323789</div>
        </div>
        <img src={logoImg} alt="" />
      </div>
      <div className="horizontal-line" />
      <div className="list">
        {list && list.map(res => {
          return (
            <div key={res.id} className="item">
              <div className="name">{res.name} {res.laber && <span className="laber">{res.laber}</span>}</div>
              <div className="value">
                <span>{res.value}</span>
                {res.share && (
                  <div className="share">
                    <img src={copyImg} alt="" /> <img src={shareImg} alt="" />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <BorrowHistory title={'Cross chain steps'} />
    </div>
  )
}

export default TokenInfo