/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-27 18:49:39
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-27 19:51:47
 */
import React from 'react';
import CardComponent  from  '../components/cardComponent/index'


const Live=({type})=>{
  return (
    <div> 
    <CardComponent />
    <CardComponent />
    <CardComponent />
    </div>
  )
}

export default Live