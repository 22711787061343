/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-27 18:49:39
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-28 19:26:52
 */
import React, { Fragment, useState } from 'react';
import { Tooltip } from 'antd';
import solanaSvg from './img/Solana.svg'
import logoSvg from './img/logo.svg'
import downwardSvg from './img/downward.svg'
import upSvg from './img/up.svg'
import linkSvg from './img/link.svg';
import illustrate from './img/illustrate.svg'
import reduceSvg from './img/reduce.svg'
import addSvg from './img/add.svg'
import Modals from '../modal/index'
import './style.scss';

const cardHeadList = [
  { id: 1, name: 'APR', icon: illustrate, value: '189.03%', tip: 'Calculated based on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.', type: "APR" },
  { id: 2, name: 'Your Staked', img: logoSvg, icon: illustrate, value: '0.0', unit: 'WORM ', money: '$0.0', tip: '', type: "staked", add: addSvg, reduce: reduceSvg },
  { id: 3, name: 'SOLANA Earned', icon: illustrate, value: "0.0", unit: 'SOLANA', tip: 'Calculated based on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.', money: '$0.0' },
  { id: 4, name: 'SOLANA Earned', icon: illustrate, value: "0.0", unit: 'SOLANA', tip: 'Calculated based on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.', money: '$0.0' },
];

const cardContentList = [
  { id: 1, name: 'Total Staked', value: '294,642,703.11 WORM', money: '$ 67,302,286.24' },
  { id: 2, name: 'Daily Reward', value: '20,831.99 SOLANA', money: '$ 105,618.19' },
  { id: 3, name: 'Ends in', value: '10d  9h  50m  58s' }
];
const contentLinkAddressList = [
  { id: 1, name: 'Project site', url: '' },
  { id: 2, name: 'Staked address', url: '' }
];
const CardComponent = () => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onJumpAddress = (url) => {
    if (url) {
      window.location.href = url
    }
  }
  const onAdd = (id) => {
    setIsModalOpen(true);
  }
  const onReduce = (id) => {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  return (
    <div className='card-component'>
      <div className='earn-solana'>Earn SOLANA <span>( Stake WORM )</span></div>
      <img src={solanaSvg} className='solanaSvg' alt="" width={80} />
      <img src={logoSvg} className='logoSvg' alt="" />
      <div className='card-head'>
        <div className='card-head-list'>
          {cardHeadList.map((res, index) => {
            return (
              <Fragment key={index}>
                <div className={`head-item ${res.type}`}>
                  <div className='illustrate'>
                    <span className='name'>{res.name}</span>
                    <Tooltip title={res.tip}>
                      <img width={16} src={illustrate} alt="" />
                    </Tooltip>
                  </div>
                  <div className="value-list">
                    <div className='value-unit-money'>
                      {res.img && <img src={res.img} alt="" width={18} />}
                      <div className={`value ${res.type}`}>{res.value} </div>
                      <div className='unit'> {res.unit} </div>
                      <div className='money'>{res.money} </div>
                    </div>
                    {res?.type === 'staked' && <div className='increase-or-decrease'><img src={res.add} alt="" onClick={() => onAdd(res.id)} /> <img src={res.reduce} alt="" onClick={() => onReduce(res.id)} />  </div>}
                  </div>
                </div>
                {index !== cardHeadList.length - 1 && <div className='vertical-line' />}
              </Fragment>
            )
          })}
        </div>
        <div className='claim'>
          <div className={`claim-buttom disable`}>Claim</div>
          <img src={open ? upSvg : downwardSvg} alt="" onClick={() => setOpen(!open)} />
        </div>
        <div className='show-view' onClick={() => setOpen(!open)}> <span>View More</span><img src={open ? upSvg : downwardSvg} alt="" /></div>
      </div>
      {open && (
        <div className='card-content'>
          <div className='card-content-list'>
            {cardContentList?.map((res, index) => {
              return (
                <div className='content-list-item' key={index}>
                  <div className='name'>{res.name}</div>
                  <div className='value'>{res.value}</div>
                  <div className='money'>{res?.money}</div>
                </div>
              )
            })}
          </div>
          <div className='content-link-add-ressList'>
            {contentLinkAddressList?.map((res, index) => {
              return (
                <div className='link-item' key={index} onClick={() => onJumpAddress(res.url)}>
                  <div className='link-name'>{res.name}</div>
                  <img src={linkSvg} alt="" width={16} />
                </div>
              )
            })}
          </div>
        </div>
      )
      }
      {isModalOpen && <Modals isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} />}
    </div>
  )
}

export default CardComponent