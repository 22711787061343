
import React from 'react';
import './index.scss';
import { Pagination } from 'antd';

function PaginationModule(porps) {
  // const inpVal = porps.inpVal || '';
  // const onChange = porps.onChange;
  // const onJumperEvent = porps.onJumperEvent;
  const hideOnSinglePage = porps.hideOnSinglePage || false;
  const showSizeChanger = porps.showSizeChanger || false;
  const totals = porps.totals;
  const PaginationConfig = porps.PaginationConfig;
  return (
    <div className="common-pagination-box">
      <div className="common-pagination-content">
        <Pagination
          total={totals}
          current={porps.current}
          defaultPageSize={PaginationConfig.defaultPageSize||9}
          pageSize={porps.pageSize || 9}
          className="pagination-module-box"
          showSizeChanger={!showSizeChanger}
          // pageSizeOptions={PaginationConfig.pageSizeOptions}
          onChange={porps.onChangeEvent}
          onShowSizeChange={porps.onChangeSize}
          hideOnSinglePage={hideOnSinglePage}
          // showTotal={total => (<div>
          //   <div className="pcb-jumper-box">
          //     <div className="pcb-jumper-left">
          //       <span>到第</span>
          //       <Input type="text" value={inpVal} onChange={onChange} />
          //       <span>页</span>
          //     </div>
          //     <Button onClick={onJumperEvent}>确定</Button>
          //   </div>
          //   <div className="pcb-total-size-box">共 {total} 页</div>
          // </div>)}
        />
      </div>
    </div>
  );
}
export default PaginationModule;