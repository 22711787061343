/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-28 20:02:58
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-28 20:51:10
 */
import React, { useState } from "react";
import { Table, DatePicker, Empty, Input } from 'antd';
import bitcoinSvg from './img/bitcoin.svg'
import linkSvg from './img/link.svg'
import solanaSvg from './img/Solana.svg'
import correctSvg from './img/correct.svg'
import promptSvg from './img/prompt.svg'
import searchSvg from './img/search.svg'

import DALL from './img/selectImg/DALL.svg'
import SATS from './img/selectImg/BSSB.svg'
import ordi from './img/selectImg/ordi.svg'
import comIMg from './img/selectImg/comIMg.svg'
import AMMX from './img/selectImg/AMMX.svg'
import MOGB from './img/selectImg/MOGB.svg'
import BSSB from './img/selectImg/BSSB.svg'
import ZOOA from './img/selectImg/ZOOA.svg'
import bdid from './img/selectImg/bdid.svg'
import maix from './img/selectImg/maix.svg'
import RAIT from './img/selectImg/RAIT.svg'
import B2BB from './img/selectImg/B2BB.svg'
import DOVA from './img/selectImg/DOVA.svg'
import RATS from './img/selectImg/RATS.svg'
import nhub from './img/selectImg/nhub.svg'
import TURT from './img/selectImg/TURT.svg'
import OXBT from './img/selectImg/OXBT.svg'
import WHEE from './img/selectImg/whee.svg'
import CHAX from './img/selectImg/chax.svg'
import IGLI from './img/selectImg/igli.svg'
import USDO from './img/selectImg/usdo.svg'
import DropDownSelect from '../../../components/drop-down/index';
import PaginationModule from '../../../components/PaginationModule/index';
import bitcoin from '../../../components/drop-down/img/bitcoin.svg'
import Solana from '../../../components/drop-down/img/Solana.svg'
import './style.scss';
const { RangePicker } = DatePicker;
const PAGESIZE = 5;
const PaginationConfig = {
  defaultPageSize: PAGESIZE
};
//默认数据
const defaultList = [
  { id: 1, icon: DALL, label: 'DALL' },
  { id: 2, icon: SATS, label: 'SATS' },
  { id: 3, icon: ordi, label: 'ordi' },
  { id: 4, icon: comIMg, label: '.com' },
  { id: 5, icon: AMMX, label: 'AMMX' },
  { id: 6, icon: MOGB, label: 'MOGB' },
  { id: 7, icon: BSSB, label: 'BSSB' },
  { id: 8, icon: ZOOA, label: 'ZOOA' },
  { id: 9, icon: bdid, label: 'bdid' },
  { id: 10, icon: maix, label: 'maix' },
  { id: 11, icon: RAIT, label: 'RAIT' },
  { id: 12, icon: B2BB, label: 'B2BB' },
  { id: 13, icon: DOVA, label: 'DOVA' },
  { id: 14, icon: RATS, label: 'RATS' },
  { id: 15, icon: nhub, label: 'nhub' },
  { id: 16, icon: TURT, label: 'TURT' },
  { id: 17, icon: OXBT, label: 'OXBT' },
  { id: 18, icon: WHEE, label: 'WHEE' },
  { id: 19, icon: CHAX, label: 'CHAX' },
  { id: 20, icon: IGLI, label: 'IGLI' },
  { id: 21, icon: USDO, label: 'USDO' }
];
//默认数据
const defaultListTwo = [
  { id: 1, icon: Solana, label: 'Solana' },
  { id: 2, icon: bitcoin, label: 'Bitcoin' },
];
const Dashboard = () => {
  const [dataSource, setDataSource] = useState(
    [
      { id: 1, Date: "2023.12.27 08:01", Amount: "184,580.04 TURT", from: "bc1pyp...c5u3", confirmed: 'Confirmed', to: 'bc1pyp...c5u3', status: 'No status yet', bridge: '0.03 ETH' },
      { id: 2, Date: "2023.12.27 08:01", Amount: "184,580.04 TURT", from: "bc1pyp...c5u3", confirmed: 'Confirmed', to: 'bc1pyp...c5u3', status: 'No status yet', bridge: '0.03 ETH' },
      { id: 3, Date: "2023.12.27 08:01", Amount: "184,580.04 TURT", from: "bc1pyp...c5u3", confirmed: 'Confirmed', to: 'bc1pyp...c5u3', status: 'No status yet', bridge: '0.03 ETH' },
      { id: 4, Date: "2023.12.27 08:01", Amount: "184,580.04 TURT", from: "bc1pyp...c5u3", confirmed: 'Confirmed', to: 'bc1pyp...c5u3', status: 'No status yet', bridge: '0.03 ETH' },
      { id: 5, Date: "2023.12.27 08:01", Amount: "184,580.04 TURT", from: "bc1pyp...c5u3", confirmed: 'Confirmed', to: 'bc1pyp...c5u3', status: 'No status yet', bridge: '0.03 ETH' }
    ]
  );
  const [selectValue, setSelectValue] = useState({});
  const [selectFromValue, setSelectFromValue] = useState({});
  const [selectToValue, setSelectToValue] = useState({});
  const [pageInfo, setPageInfo] = useState({
    pageNum: 1,
    totals: 50,
    pageSize: PAGESIZE,
  });
  const [inpVal, setInpVal] = useState('');
  const [pageSize, setPageSize] = useState(PAGESIZE);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      render: (text) => {
        return <div className="item-date"> {text}</div>
      }
    },
    {
      title: 'Amount(Asset)',
      dataIndex: 'Amount',
      key: 'Amount',
      render: (text) => {
        return <div className="item-value"> {text}</div>
      }
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      render: (text) => {
        return <div className="item-from"> <img src={bitcoinSvg} alt="" /> <span>{text} </span> <img src={linkSvg} alt="" /> </div>
      }
    },
    {
      title: 'Confirmed',
      dataIndex: 'confirmed',
      key: 'confirmed',
      render: (text) => {
        return <div className="item-confirmed"> <img src={correctSvg} alt="" /> <span>{text} </span> </div>
      }
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      render: (text) => {
        return <div className="item-from"> <img src={solanaSvg} alt="" /> <span>{text} </span> <img src={linkSvg} alt="" /> </div>
      }
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return <div className="item-status"> <img src={promptSvg} alt="" /> <span>{text} </span> </div>
      }
    },
    {
      title: 'Bridge Fee',
      dataIndex: 'bridge',
      key: 'bridge',
      render: (text) => {
        return <div className="item-value"> {text}</div>
      }
    }
  ];
  const onPaginationChange = (page, pageSize) => {
    setPageInfo({ ...pageInfo, ...{ limit: pageSize, pageNum: page } });
    setPageSize(pageSize);
  };

  const onPaginationSizeChange = (current, size) => {
    setPageInfo({ ...pageInfo, ...{ limit: size } });
    setPageSize(size);
  };

  const onChange = (e) => {
    setInpVal(e.target.value.replace(/\D/g, '').replace(/^0{1,}/g, ''));
  };
  //搜索
  const onSearch = () => {
    console.log('onSearch')
  }
  const locale = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No Record'} />,
  };
  return (
    <div className="table-box">
      <div className="action-bar">
        <div className="bar">
          <DropDownSelect List={defaultList} className='dropDownSelect' defaultValue='All  Assets' selectValue={selectValue} onSelectValue={(value) => setSelectValue(value)} />
          <RangePicker onChange={(date) => { console.log('date', date) }} className="range-picker" placeholder={['MM/DD/YY', 'MM/DD/YY']} />

          <div className="from-dropDownSelect">
            <span className="name">From</span>
            <DropDownSelect
              List={defaultListTwo}
              defaultValue='All chain'
              selectValue={selectFromValue}
              onSelectValue={(value) => setSelectFromValue(value)}
            />
          </div>

          <div className="from-dropDownSelect">
            <span className="name">To</span>
            <DropDownSelect
              List={defaultListTwo}
              defaultValue='All chain'
              selectValue={selectToValue}
              onSelectValue={(value) => setSelectToValue(value)}
            />
          </div>
        </div>
        <div className="search"> <Input placeholder="Search" suffix={<img src={searchSvg} alt="" onClick={onSearch} />} /></div>
      </div>
      <Table
        dataSource={dataSource}
        rowKey={'id'}
        columns={columns}
        pagination={false}
        locale={locale}
      />
      <div className='pagination-box-order'>
        <PaginationModule
          inpVal={inpVal}
          totals={pageInfo.totals}
          pageSize={pageSize}
          onChangeEvent={onPaginationChange}
          current={pageInfo.pageNum}
          onChangeSize={onPaginationSizeChange}
          PaginationConfig={PaginationConfig}
          onChange={onChange}
          hideOnSinglePage={false}
          showSizeChanger={true}
        />
      </div>
    </div>
  )
}

export default Dashboard;