/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-27 18:28:39
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-28 20:13:37
 */

import { Outlet } from 'react-router-dom';
import Footer from './footer';
// import Herder from './header';
import './index.scss';

const BaseLayout = () => {
  return (
    <div className='bridgge-box'>
      {/* <Herder /> */}
      <div className='layout-content'>
          <Outlet />
      </div>
      <Footer />
    </div>
  );

};
export default BaseLayout;