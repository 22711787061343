/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-21 15:45:13
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-28 20:16:10
 */
import React, { useState } from "react";
import BorrowComponent from '../Bridgge/index'
import Header from "../../layouts/header/index";
import TokenInfo from '../token-Info/index'
import DropDownSelect from '../../components/drop-down/index'
import bitcoin from '../../components/drop-down/img/bitcoin.svg'
import Solana from '../../components/drop-down/img/Solana.svg'
import { Tabs } from 'antd';
import './style.scss';


//默认数据
const defaultList = [
  { id: 1, icon: Solana, label: 'Solana' },
  { id: 2, icon: bitcoin, label: 'Bitcoin' },
];
//默认选择第一条数据
const defalueShowValue = defaultList[1];

const Index = () => {
  const [selectValue, setSelectValue] = useState(defalueShowValue);

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Bridge',
      children: <BorrowComponent />,
    },
    {
      key: '2',
      label: 'Token Info',
      children: <TokenInfo />,
    },
  ];
  return (
    <div className="worm-style">
      <Header />
      <div className="tabComponent">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <DropDownSelect List={defaultList} className='dropDownSelect' selectValue={selectValue} onSelectValue={(value) => setSelectValue(value)} />
      </div>
    </div>

  )
}

export default Index