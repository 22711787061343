import React, { useState } from 'react';
import downwardSvg from './img/downward.svg'
import upSvg from './img/up.svg'
import linkSvg from './img/link.svg';
import './style.scss';

const CardComponent = ({ item }) => {
  const [open, setOpen] = useState(false);

  const onJumpAddress = (url) => {
    if (url) {
      window.location.href = url
    }
  }
  return (
    <div className='attestation-card'>
      <div className='attestation-title'>{item?.title} </div>
      <img src={item?.logo} className='solanaSvg' alt="" width={70} />
      <div className='card-head'>
        {item?.money && <div className='money'>{item?.money}</div>}
        <div className='card-head-list'>
          {item.cardHeadList.map((res, index) => {
            return (
              <div className='head-item' key={index}>
                <div className='name'>{res.name}</div>
                <div className='img-list'>
                  {res.imgList.map((url, index) => {
                    return <img src={url} alt="" key={index} />
                  })}
                </div>
              </div>
            )
          })}
        </div>
        <div className='volume'>
          <div className='volume-title'>{item?.volume?.title}</div>
          {item?.volume?.list?.map((res, index) => {
            return (
              <div className='volume-style' key={index}>
                <div className='name'>{res.name}</div>
                <div className='value'>{res.value}</div>
              </div>
            )
          })}
        </div>
        <div className='show-view' onClick={() => setOpen(!open)}> <span>View More</span><img src={open ? upSvg : downwardSvg} alt="" /></div>
      </div>
      {open && (
        <div className='card-content'>
          <div className='ress-title'>{item.amountName}</div>
          <div className='ress-value'>{item.amountMoney}</div>
          <div className='content-link-add-resList'>
            <div className='ress-title'>{item?.address?.title}</div>
            {item?.address?.List?.map((res, index) => {
              return (
                <div className='link-item' key={index} onClick={() => onJumpAddress(res.url)}>
                  <div className='link-name'>{res.name}</div>
                  <div className='link-value'>
                    {res.img && <img className='link-logo' src={res.img} alt="" width={16} />}
                    <span>{res.value}</span>
                    <img src={linkSvg} alt="" width={16} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default CardComponent