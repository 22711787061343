/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-28 20:02:58
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-29 15:06:12
 */
import React, { useEffect, useState } from "react";
import Header from "../../layouts/header/index";
import bitcoin from '../image/bitcoin.svg'
import Solana from '../image/Solana.svg'
import declineSvg from './img/decline.svg'
import riseSvg from './img/rise.svg'
import coinSvg from './img/coin.svg'
import TransactionTable from './transaction-history-table/index';
import Attestation from './attestation/index';
import solanaSvg from './attestation/img/Solana.svg'
import logoSvg from './attestation/img/logo.svg'
import './style.scss'

const networksList = [bitcoin, Solana]
const dashboardCardList = [
  { id: 1, name: 'Number of Assets Supported', value: "16 Tokens", riseNum: '+1', rise: true },
  { id: 2, name: 'MORM Total Volume', value: "$ 463,747,435.97", riseNum: '12%', rise: true },
  { id: 3, name: 'Morm Price', nameIcon: coinSvg, value: "16 Tokens", riseNum: '+1', rise: false },
  { id: 4, name: 'MUBI Ecosystem', nameIcon: coinSvg, but: "Stake" },
]

const dataList = [
  {
    id: 1,
    logo: solanaSvg,
    title: 'Earn SOLANA',
    money: '$ 0.241665',
    cardHeadList: [
      { id: 1, name: 'Origin', imgList: [bitcoin, Solana] },
      { id: 2, name: 'Target', imgList: [bitcoin, Solana] },
    ],
    volume: {
      title: 'Cross Chain Volume',
      list: [{ id: 1, name: '935,845,499.29 MUBI', value: "$ 226,161,102.59" }]
    },
    amountName: 'Existing cross-chain amount',
    amountMoney: '2,147,536,012,316.54 MOGB',
    address: {
      title: 'Attestation Address',
      List: [
        { id: 1, name: 'Hot wallet', value: "bc1pyp...c5u3", url: '', img: bitcoin },
        { id: 2, name: 'Cold wallet', value: "bc1pyp...c5u3", url: '' }
      ]
    }
  }, {
    id: 2,
    logo: logoSvg,
    title: 'Earn SOLANA',
    money: '$ 0.241665',
    cardHeadList: [
      { id: 1, name: 'Origin', imgList: [bitcoin] },
      { id: 2, name: 'Target', imgList: [bitcoin, Solana] },
    ],
    volume: {
      title: 'Cross Chain Volume',
      list: [{ id: 1, name: '935,845,499.29 MUBI', value: "$ 226,161,102.59" }]
    },
    amountName: 'Existing cross-chain amount',
    amountMoney: '2,147,536,012,316.54 MOGB',
    address: {
      title: 'Attestation Address',
      List: [
        { id: 1, name: 'Hot wallet', value: "bc1pyp...c5u3", url: '', img: bitcoin },
        { id: 2, name: 'Cold wallet', value: "bc1pyp...c5u3", url: '' }
      ]
    }
  }, {
    id: 3,
    logo: solanaSvg,
    title: 'Earn SOLANA',
    money: '$ 0.241665',
    cardHeadList: [
      { id: 1, name: 'Origin', imgList: [bitcoin, Solana] },
      { id: 2, name: 'Target', imgList: [bitcoin] },
    ],
    volume: {
      title: 'Cross Chain Volume',
      list: [{ id: 1, name: '935,845,499.29 MUBI', value: "$ 226,161,102.59" }]
    },
    amountName: 'Existing cross-chain amount',
    amountMoney: '2,147,536,012,316.54 MOGB',
    address: {
      title: 'Attestation Address',
      List: [
        { id: 1, name: 'Hot wallet', value: "bc1pyp...c5u3", url: '', img: bitcoin },
        { id: 2, name: 'Cold wallet', value: "bc1pyp...c5u3", url: '' }
      ]
    }
  }, {
    id: 4,
    logo: bitcoin,
    title: 'Earn SOLANA',
    money: '$ 0.241665',
    cardHeadList: [
      { id: 1, name: 'Origin', imgList: [bitcoin, Solana] },
      { id: 2, name: 'Target', imgList: [bitcoin, Solana] },
    ],
    volume: {
      title: 'Cross Chain Volume',
      list: [{ id: 1, name: '935,845,499.29 MUBI', value: "$ 226,161,102.59" }]
    },
    amountName: 'Existing cross-chain amount',
    amountMoney: '2,147,536,012,316.54 MOGB',
    address: {
      title: 'Attestation Address',
      List: [
        { id: 1, name: 'Hot wallet', value: "bc1pyp...c5u3", url: '', img: bitcoin },
        { id: 2, name: 'Cold wallet', value: "bc1pyp...c5u3", url: '' }
      ]
    }
  }

]

const Dashboard = () => {
  const [showHeadBg, setShowHeadBg] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.srcElement.scrollingElement.scrollTop;
    setShowHeadBg(scrollTop > 70);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className='dashboard-style'>
      <div className={`dashboard-head ${showHeadBg ? "show-head-bg" : ""}`}>
        <Header />
        <div className="dashboard-card">
          <div className="item-head">
            <div className="name">Dashboard</div>
            <div className="value">
              <span>Supported Networks</span>
              <div className="networks-list">{networksList.map((url, index) => <img key={index} style={{ marginRight: networksList.length - 1 !== index ? '10px' : "" }} src={url} alt="" />)}</div>
            </div>
          </div>
          <div className="item-card">
            {dashboardCardList.map((res, index) => {
              return (
                <div className="card-list-item" key={res.id}>
                  <div className="item-bg"/>
                  <div className="name">{res.nameIcon && <img src={res.nameIcon} width={16} alt="" />} <span>{res.name}</span> </div>
                  {index === dashboardCardList.length - 1 ? (
                    <div className="buttom">{res.but}</div>
                  ) : (
                    <>
                      <div className="value">{res.value}</div>
                      <div className="riseNum"><span className={`${!res.rise && "rise"}`}>{res.riseNum}</span> <img src={!res.rise ? declineSvg : riseSvg} alt="" /></div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      
      <div className="dashboard-content">
        <div className="title">Transaction History</div>
          <TransactionTable />
        <div className="title">Attestation</div>
          <div className="attestation-list">
            {dataList.map((item, index) => <div className="item" key={index}><Attestation item={item} /></div>)}
          </div>
        </div>
    </div>
  )
}

export default Dashboard;