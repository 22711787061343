import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import logo from './images/logo.svg';
import github from './images/github.svg';
import medium from './images/medium.svg';
import twitter from './images/twitter.svg';
import discord from './images/discord.svg';
import githubColor from './images/githubColor.svg';
import mediumColor from './images/mediumColor.svg';
import twitterColor from './images/twitterColor.svg';
import discordColor from './images/discordColor.svg';
import {defaultMeun} from '../../router/index'
import './footer.scss'
const imgList = [
  { id: 1, icon: github, highlight: githubColor },
  { id: 2, icon: medium, highlight: mediumColor },
  { id: 3, icon: twitter, highlight: twitterColor },
  { id: 4, icon: discord, highlight: discordColor }
];
const Foot = () => {
  const [newId, setId] = useState(0);
  const navigate = useNavigate()
  const Location = useLocation();
  const [selectMenu, setSelectMenu] = useState(Location.pathname);
  const onSelectNavigate = (path) => {
    setSelectMenu(path)
    navigate(`${path}`);
  }

  const onMouseEnter = (id) => setId(id);
  const onMouseLeave = () => setId(0);
  return (
    <div className="foots-style">
      <div className="foot-menu">
        {defaultMeun.map((res,index) => {
          return <div key={res.index} className={`item ${selectMenu === res.path && 'select'}`} onClick={()=>onSelectNavigate(res.path)}>{res.laber}</div>
        })}
      </div>
      <div className="worm-center-logo">
        <img className="logo" src={logo} alt="" />
        <div className="titles">© 2023 worm, All rights reserved.</div>
      </div>
      <div className="iconList">
        {imgList && imgList.map(res => {
          return (
            <div
              key={res.id}
              className="icon-item"
              onMouseEnter={() => onMouseEnter(res.id)}
              onMouseLeave={() => onMouseLeave()}
            >
              <img
                src={res.id === newId ? res.highlight : res.icon}
                alt=""
                width={16}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
};

export default Foot
