/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-27 18:28:39
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-28 20:11:55
 */
// import { Navigate } from 'react-router-dom';
import Layout from '../layouts/index'
import LayoutWorm from '../layouts/layout'
import NotFind from '../components/notFind/index'
import Worm from '../pages/worm/index'
import Home from '../pages/index'
import Staking from '../pages/staking/index'
import Dashboard  from '../pages/dashboard/index'

const defaultMeun = [{laber:'Home',path:'/'},{laber:'Bridgge',path:'/Bridgge'},{laber:'Staking',path:'/staking'},{laber:'Dashboard',path:'/dashboard'}];
const routers =[
  {
    path: '/',
    element: <Layout showHeaders={false} />,
    children: [
      {
        key: 1,
        label: 'worm',
        path: '/',
        element: <Home />,
      }
    ]
  },
  {
    path: '/',
    element: <LayoutWorm />,
    children: [
      {
        key: 1,
        label: 'Bridgge',
        path: '/bridgge',
        element: <Worm />,
      },
      {
        key: 2,
        label: 'staking',
        path: '/staking',
        element: <Staking />,
      },
      {
        key: 3,
        label: 'dashboard',
        path: '/dashboard',
        element: <Dashboard />,
      },
    ],
  },
  {
    title: '404',
    path: '*',
    element: <NotFind />,
  },
]
export {
  routers,
  defaultMeun
};

