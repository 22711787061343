import React from "react";
import nodataImg from './img/nodata.svg'
import './style.scss';


const WalletNotConnected=()=>{
  return (
    <div className="please-connect-wallet">
        <img src={nodataImg} alt="" />
        <div className="please">Please connect your EVM wallet</div>
    </div>
  )
}

export default WalletNotConnected