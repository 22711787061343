/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-21 19:46:05
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-21 20:05:34
 */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import logo from './img/logo.svg'
import menuImg from './img/menu.svg'
import { Drawer, Dropdown } from 'antd';
import { defaultMeun } from '../../router/index'
import DropDownSelect from '../../components/drop-down/index'
import Solana from '../../components/drop-down/img/Solana.svg'
import bitcoinImg from './img/bitcoin.svg'
import copySvg from './img/copy.svg'
import ethImg from './img/image.svg'
import exitImg from './img/exit.svg'
import './style.scss';
import { useWallet } from "@solana/wallet-adapter-react";
import { useWalletDialog } from '@solana/wallet-adapter-material-ui';
import { onCopyToText } from "../../util";

const defaultList = [
  { id: 1, icon: Solana, label: 'Solana' },
  { id: 2, icon: bitcoinImg, label: 'Bitcoin' },
];

const ConnectButton = ({showDrawer}) => {
  const { connected, publicKey, disconnect } = useWallet();
  const { setOpen } = useWalletDialog();
  // const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(defaultList[0]);

  // connect wallet
  const handleConnect = async () => {
    try {
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="right">
      {!connected ? (
        <div className="connect-wallet" onClick={handleConnect}>Connect wallet</div>
      ) : (
        <div className="connection-successful">
          <DropDownSelect List={defaultList} className='dropDownSelect' selectValue={selectValue} onSelectValue={(value) => setSelectValue(value)} />
          <div className="bit" >
            <Dropdown
              dropdownRender={() => <div onClick={disconnect} className="disconnect"><span>Disconnect</span> <img src={exitImg} alt="" /></div>}
              placement="bottomLeft"
              overlayClassName="dropdown-style"
            >
              <div className="eth-img-name" onClick={_=>onCopyToText(publicKey?.toBase58())}>
                <img className="ethImg" src={ethImg} alt="" />
                <span>  {publicKey?.toBase58().slice(0, 6) + '...' + publicKey?.toBase58().slice(-4)}</span>
              </div>
            </Dropdown>
            <img className="copySvg" src={copySvg} alt="" width={12} onClick={_=>onCopyToText(publicKey?.toBase58())} />
          </div>


        </div>
      )}
      <div className="menuImg" onClick={showDrawer}><img src={menuImg} alt="" /></div>
    </div>
  )
}
const Index = () => {
  const navigate = useNavigate()
  const Location = useLocation();
  const [selectName, setSelectName] = useState(Location.pathname);
  const [open, setOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [showExit, setShowExit] = useState(false);

  const onSelectNavigate = (path) => {
    setSelectName(path)
    setOpen(false);
    navigate(`${path}`);
  }

  const onClose = () => setOpen(false);
  // const onPublicHead = () => {
  //   return (
  //     <div className="right">
  //       {!successful ? (
  //         <div className="connect-wallet" onClick={() => setSuccessful(true)}>Connect wallet</div>
  //       ) : (
  //         <div className="connection-successful">
  //           <DropDownSelect List={defaultList} className='dropDownSelect' selectValue={selectValue} onSelectValue={(value) => setSelectValue(value)} />
  //           <div className="bit">
  //             <Dropdown
  //               dropdownRender={() => <div className="disconnect"><span>Disconnect</span> <img src={exitImg} alt="" /></div>}
  //               placement="bottomLeft"
  //               overlayClassName="dropdown-style"
  //             >
  //               <div className="eth-img-name">
  //                 <img className="ethImg" src={ethImg} alt="" />
  //                 <span>A4hy...AJ1to2</span>
  //               </div>
  //             </Dropdown>
  //             <img className="copySvg" src={copySvg} alt="" width={12} />
  //           </div>
  //         </div>
  //       )}
  //       <div className="menuImg" onClick={showDrawer}><img src={menuImg} alt="" /></div>
  //     </div>
  //   )
  // }
  return (
    <div className="index-head">
      <div className="logo"> <img src={logo} alt="" /></div>
      <div className="memu">
        {defaultMeun.map((res, index) => {
          return (
            <div className={`item ${res.path === selectName && 'select'}`} key={index} onClick={() => onSelectNavigate(res.path)}>{res.laber}</div>
          )
        })}
      </div>
      <ConnectButton showDrawer={() => setOpen(true)}/>
      <Drawer
        title={null}
        placement="top"
        onClose={onClose}
        rootClassName='drawer-style'
        open={open}>
        <div className="drawer">
          <div className="logo"> <img src={logo} alt="" width='100' /></div>
          <ConnectButton showDrawer={() => setOpen(false)} />
        </div>
        <div className="memu">
          {defaultMeun.map((res, index) => {
            return (
              <div className={`item ${res.path === selectName && 'select'}`} key={index} onClick={() => onSelectNavigate(res.path)}>{res.laber}</div>
            )
          })}
        </div>
      </Drawer>
    </div>
  )
}

export default Index