/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-27 18:28:39
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-27 19:08:17
 */
import React, { useState } from "react";
import { Tabs } from 'antd';
import Live from './live/index';
import Finshed from './finshed/index';

// import solanaSvg from './components/cardComponent/img/Solana.svg'
// import logoSvg from './components/cardComponent/img/logo.svg'
// import downwardSvg from './components/cardComponent/img/downward.svg'
// import upSvg from './components/cardComponent/img/up.svg'
// import linkSvg from './components/cardComponent/img/link.svg';
// import illustrate from './components/cardComponent/img/illustrate.svg'
// import reduceSvg from './components/cardComponent/img/reduce.svg'
// import addSvg from './components/cardComponent/img/add.svg'

import './style.scss';
const TabList = ['Staked only', 'Incentivized only'];
// const cardHeadList = [
//   { id: 1, name: 'APR', icon: illustrate, value: '189.03%', tip: 'Calculated based on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.', type: "APR" },
//   { id: 2, name: 'Your Staked', img: logoSvg, icon: illustrate, value: '0.0', unit: 'WORM ', money: '$0.0', tip: '', type: "staked", add: addSvg, reduce: reduceSvg },

//   { id: 3, name: 'SOLANA Earned', icon: illustrate, value: "0.0", unit: 'SOLANA', tip: 'Calculated based on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.', money: '$0.0' },
//   { id: 4, name: 'SOLANA Earned', icon: illustrate, value: "0.0", unit: 'SOLANA', tip: 'Calculated based on current rates and subject to change based on various external variables. It is a rough estimate provided for convenience only, and by no means represents guaranteed returns.', money: '$0.0' },
// ];

// const cardContentList = [
//   { id: 1, name: 'Total Staked', value: '294,642,703.11 WORM', money: '$ 67,302,286.24' },
//   { id: 2, name: 'Daily Reward', value: '20,831.99 SOLANA', money: '$ 105,618.19' },
//   { id: 3, name: 'Ends in', value: '10d  9h  50m  58s' }
// ];
// const contentLinkAddressList = [
//   { id: 1, name: 'Project site', url: '' },
//   { id: 2, name: 'Staked address', url: '' }
// ];
const Index = () => {
  const [rightTabkey, setRightTabkey] = useState('Staked only')
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Live',
      children: <Live type={rightTabkey}  />,
    },
    {
      key: '2',
      label: 'Finshed',
      children: <Finshed type={rightTabkey} />,
    },
  ];
  return (
    <div className="tab-staking">
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      <div className="right-tab-list">
        {TabList.map((item, index) => {
          return <div className={`item ${item !== rightTabkey && 'select'}`} key={index} onClick={() => setRightTabkey(item)}>{item}</div>
        })}
      </div>
    </div>
  )
}

export default Index