/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-21 19:42:13
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-21 20:54:15
 */
import { useState, useEffect } from "react";
import Header from "../layouts/header/index";
import wormSvg from './image/WORM.svg'
import bitcoin from './image/bitcoin.svg'
import Solana from './image/Solana.svg'
import Group from './image/Group.svg'
import flowpathImg from './image/how-works/flowpath.svg'
import { ImageViewer } from 'antd-mobile'
import './style.scss';

const WormList = [
  {
    id: 1, laber: 'The first point', name: "Vision At Solm",
    content: "Establish a safe, friendly, smooth and fast seamless cross-chain channel, specially provided for the Bitcoin ecosystem and Solana.<br/><br/>Our mission is to build seamless cross-chain products between Bitcoin and Solana. We will promote the injection of liquidity into each other's various basic protocols, various application scenarios, and various decentralized ecosystems of the Solana ecology and the Bitcoin ecology."
  },
  { id: 2, laber: 'The first point', name: "Scalability At Solm", content: "Scalability is an urgently needed part of the current Bitcoin network. We are committed to using Solana’s scalability to inject stronger liquidity into the Bitcoin ecosystem, while injecting the privacy, security and specificity of the Bitcoin ecosystem into Solana.<b> Worm Bridge is the first cross-chain bridge protocol built on the Solana ecosystem and the Bitcoin ecosystem.</b>" },
  { id: 3, laber: 'The first point', name: "User Experience At Solm", content: "In interaction, user experience is particularly important. <b>Worm not only provides solutions for users, but also provides support for Solana and Bitcoin ecosystem developers.</b> You can use Solm at any time and provide suggestions for Solm, and we will listen carefully." },
  { id: 4, laber: 'The first point', name: "Security At Solm", content: "The security of user assets is one of our most important tasks, and we use multi-signature cold wallets to protect user accounts and funds. And Solm's security module is regularly tested to ensure isolation and protection while eliminating all factors that are detrimental to users. All of our processes have some degree of added protection. You can use Solm for cross-chain with confidence." }
];


const Worm = () => {
  const [showHeadBg, setShowHeadBg] = useState(false);
  const [visible, setVisible] = useState(false)

  const handleScroll = (e) => {
    const scrollTop = e.srcElement.scrollingElement.scrollTop;
    setShowHeadBg(scrollTop > 70);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
  };

  return (
    <div className='worm-main'>
      <div className={`first-screen ${showHeadBg ? "show-head-bg" : ""}`}>
        <Header scrollToAnchor={scrollToAnchor} />
        <div className='worm'>
          <div className='content paddingTop'>
            <div className='title'>$WORM</div>
            <div className='tips'>Seamless interchange between <img src={Solana} alt="" /><span>solana</span> ecology and  <img src={bitcoin} alt="" />  <span>bitcoin</span> ecology</div>
            <div className='buttom-style'>Launch App</div>
          </div>
          <div className='wormSvg'><img src={wormSvg} alt="" /></div>
          <div className='mountain'></div>
        </div>
        <div className='worm-Bridge'>
          <div className='groupSvg'><img src={Group} alt="" /></div>
          <div className='content'>
            <div className='title'>WORM Bridge </div>
            <div className='tip-p'>
              <p>Worm Bridge is <span>the first cross-chain bridge protocol<br /> built on the Solana ecosystem and the Bitcoin <br />ecosystem,</span> aiming to establish seamless and silky<br />
                network transmission <span>between Bitcoin and Solana <br />tokens.</span></p>

              <p>You can perform cross-chain bridges at any time to<br /> facilitate unparalleled liquidity for these tokens.<br /></p>
              Our vision: <span>to establish a safe, friendly, smooth and fast<br /> seamless cross-chain channel, specially provided for<br /> Solana and Bitcoin.</span>
            </div>
            <div className='buttom-style'>Enter Bridge</div>
          </div>
        </div>
      </div>

      <div className='worm-vision'>
        <div className='title'>WORM Vision</div>
        <div className='moonSvg' />
        <div className='card-list'>
          {WormList.map(res => {
            return (
              <div className={`item-card background${res.id}`} key={res.id}>
                <div className='item-laber'>{res.laber}</div>
                <div className='item-name'>{res.name}</div>
                <div className='item-content' dangerouslySetInnerHTML={{ __html: res.content }} />
              </div>
            )
          })}
        </div>
      </div>
      <div className='how-works'>
        <div className='how-works-head'>
          <div className='title'>How it<br />all works</div>
          <div className='how-works-content'>
            <p>
              WORM is not only realizing <b>the seamless transfer of tokens between Bitcoin<br />
                ecosystem and Solana ecosystem,</b> but also focusing on building real user<br />
              solutions for decentralized construction.
            </p>
            <p>
              First, users can use WORM to <b>transfer Bitcoin ecosystem tokens to a <br />
                dedicated BTC address at any time.</b>
            </p>
            After confirmation, these tokens will be minted on the Solana network, and <br />
            users can seamlessly transfer Solana network tokens back to the Bitcoin <br />
            network at any time. <b>Our vision and products don’t stop there.</b>
          </div>
        </div>
        <div className="flowpathImg" onClick={() => setVisible(true)}>
          <img src={flowpathImg} alt="" />
        </div>
        <div className='rockery' />
      </div>
      <ImageViewer
        image={flowpathImg}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
      />
    </div>
  )
}

export default Worm