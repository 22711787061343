import React, { useState } from "react";
import { Input, Spin } from 'antd';
import convert from './img/convert.svg'
import serviceImg from './img/service.svg'
import warnImg from './img/warn.svg'
// import loadImg from './img/load.svg'
import { LoadingOutlined } from '@ant-design/icons';
import DropDownSelect from '../../components/drop-down/index';
import bitcoin from '../../components/drop-down/img/bitcoin.svg'
import Solana from '../../components/drop-down/img/Solana.svg'
import './style.scss';

//默认数据
const defaultList = [
  { id: 1, icon: Solana, label: 'Solana' },
  { id: 2, icon: bitcoin, label: 'Bitcoin' },
];
//默认选择第一条数据
const defalueShowValue = defaultList[0];
const Borrow = () => {
  const [value, setValue] = useState();
  const [load, setLoad] = useState(false);
  const [selectValue, setSelectValue] = useState(defalueShowValue);

  return (
    <div className="borrow-main">
      <div className="borrow-xmud">
        <div className="conversionBlock">
          <div className="label-style">
            <div className="dollar">From</div>
            <div className="available-max">
              <div className="available">Balance: <span>0.032 SOL</span></div>
              <div className="max">Max</div>
            </div>
          </div>
          <div className="collateral-input">
            <DropDownSelect List={defaultList} selectValue={selectValue} onSelectValue={(value) => setSelectValue(value)} />
            <Input placeholder="at least 0.001" className="selectValue" />
          </div>
        </div>

        <div className="convert"><img src={convert} alt="" /></div>
        <div className="conversionBlock">
          <div className="label-style">
            <div className="dollar">To</div>
            <div className="available-max">
              <div className="available">Balance: </div>
              {load ? (
                <Spin size='small' indicator={<LoadingOutlined spin tyle={{ fontSize: 12 }} />} spinning={load} />
              ) : (
                <div className="balance-value"> 0.2231</div>
              )}
            </div>
          </div>
          <div className="collateral-input">
            <DropDownSelect List={defaultList} selectValue={selectValue} onSelectValue={(value) => setSelectValue(value)} />
            <Input placeholder="0.00" value={value} onChange={(e) => setValue(e.target.value)} />
          </div>
        </div>
      </div>

      <div className="connect-wallet disable">Connect Wallet</div>
      <div className="connect-wallet insufficient-funds">insufficient funds</div>

      <div className="service">🛢 Service fees: 0.03 ETH <img src={serviceImg} alt="" /></div>
      <div className="makers-provide"><img src={warnImg} alt="" /> Makers provide 10 ETH for liquidity.</div>
    </div>
  )
}

export default Borrow