import React from "react";
import { Modal, Checkbox, Input } from 'antd';
import './style.scss'


const StakeWORM = ({ isModalOpen = false, handleOk, handleCancel }) => {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <div className="ant-modal-style">
      <Modal
        title="Stake WORM"
        className="home-modal-style"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="conversionBlock">
          <div className="available-max">
            <div className="available">Available: <span>0.032 SOL</span></div>
            <div className="max">Max</div>
          </div>
          <div className="collateral-input">
            <div className="ERC">ERC 20</div>
            <Input placeholder="Amount 0.001" className="selectValue" />
          </div>
        </div>
        <div className="check-value">
          <Checkbox onChange={onChange}>The staked tokens and staking income are locked for 3 days by default. Each time the stake is increased, the locking time will be reset.</Checkbox>
        </div>
        <div className={`stake-but ${false && 'disable'}`}>Stake</div>
      </Modal>
    </div>
  )
}

export default StakeWORM