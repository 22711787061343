import React, { useState } from "react";
import { Timeline } from 'antd';
import upImg from './img/up.svg'
import cloneImg from './img/clone.svg'
import logoImg from './img/logo.svg'
import './style.scss';

const BorrowHistory = ({ title }) => {
  const [showTable, setShowTable] = useState(true);

  return (
    <div className="borrow-history">
      <div className={`tilte ${!showTable && 'clone-border'}`}>
        <div className='logoImg-title'>
          <img src={logoImg} alt="" />
          <span>{title || 'Borrow History'}</span>
        </div>
        <img src={showTable ? upImg : cloneImg} width={12} alt="" onClick={() => setShowTable(!showTable)} />
      </div>
      {showTable && (
        <div className="timeline">
          <Timeline
            items={[
              {
                children: <>
                  <div className="title">Cross-chain from Bitcoin to EVM</div>
                  <div className="connect">Connect Bitcoin wallet.Select the ETH or BNB chain, enter the receiving address on the EVM chain,wait for 3-4 blocks, and after minting is successful, tokens are successfully bridged over to the EVM network.</div>
                </>,
              },
              {
                children: <>
                <div className="title">Cross-chain from Bitcoin to EVM</div>
                <div className="connect">Connect Bitcoin wallet.Select the ETH or BNB chain, enter the receiving address on the EVM chain,wait for 3-4 blocks, and after minting is successful, tokens are successfully bridged over to the EVM network.</div>
              </>            
              },
              {
                dot:null,
                children: '',
              }
            ]}
          />
          <div className="history">View all history →</div>
        </div>
      )}
    </div>
  )
}

export default BorrowHistory