import React, { useState } from "react";
import downOutlined from './img/downOutlined.svg'
import { Select } from 'antd';
import './style.scss';

const DropDownSelect = ({ className, selectValue = {}, List =[], onSelectValue, defaultValue = '' }) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const getLbael = item => {
    if (item instanceof Object && JSON.stringify(item) !== '{}') {
      return {
        value: item?.label,
        label: (
          <div className='getLbael'>
            <img src={item?.icon} alt="" />
            <div className='item-label'>{item?.label}</div>
          </div>
        )
      };
    }
    if(JSON.stringify(item) === '{}'){
      return defaultValue
    }
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSelectItem = (items) => {
    setSelectOpen(false);
    onSelectValue(items)
  };
  return (
    <Select
      defaultValue={defaultValue}
      className={`dropDown-select-style ${className}`}
      popupClassName='dropDown-popup-select'
      value={getLbael(selectValue)}
      onChange={handleChange}
      open={selectOpen}
      suffixIcon={<img src={downOutlined} alt='' />}
      onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
      dropdownRender={() => {
        return (
          List.map(res => {
            return (
              <div key={res.id} className='select-item' onClick={() => onSelectItem(res)}>
                <img src={res.icon} alt="" />
                <div className='name'>{res.label}</div>
              </div>
            );
          })
        );
      }}
    />
  )
}

export default DropDownSelect