/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-21 15:45:13
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-21 19:09:33
 */
import React from "react";
import './footer.scss'

const Foot = () => {

  return (
    <div className="index-foot">

      © 2023 worm, All rights reserved.
      {/* {foontList.map(res => {
        return (
          <div className="item" key={res}>{res}</div>
        )
      })} */}
    </div>
  )
};

export default Foot
