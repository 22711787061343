/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-06 17:29:21
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-06 17:56:58
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';


import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletDialogProvider, WalletMultiButton } from '@solana/wallet-adapter-material-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, SolflareWalletAdapter  } from '@solana/wallet-adapter-wallets';

require('@solana/wallet-adapter-react-ui/styles.css');

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',  // 主要的颜色
    },
    secondary: {
      main: '#fff',  // 次要的颜色
    },
    error: {
      main: '#ff5252',  // 错误颜色
    },
    background: {
      default: '#fff',  // 背景颜色
      paper: '#fff', // 用于卡片、对话框等的背景颜色
    },
  }
});
const network = WalletAdapterNetwork.Mainnet;
  // const endpoint = clusterApiUrl(network);
  const endpoint = 'https://solana-mainnet.g.alchemy.com/v2/ZT3c4pYf1inIrB0GVDNR7nx4LwyED5Ci'
  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter()
  ];
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletDialogProvider>
        <App />
        </WalletDialogProvider>
      </WalletProvider>
    </ConnectionProvider>
    </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
