/*
 * @Descripttion: 
 * @version: 
 * @Author: Yj
 * @Date: 2023-12-28 09:26:23
 * @LastEditors: Yj
 * @LastEditTime: 2023-12-28 20:27:20
 */
import React, { useEffect, useState } from "react";
import Header from "../../layouts/header/index";
import rotateImg from './img/rotate.svg'
import bitcoin from '../image/bitcoin.svg'
import Solana from '../image/Solana.svg'
import WalletNotConnected from './walletNotConnected'
import TabComponent from './tabs/index'
import './style.scss'

const Staking = () => {
  const [showHeadBg, setShowHeadBg] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.srcElement.scrollingElement.scrollTop;
    setShowHeadBg(scrollTop > 70);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
  };


  return (
    <div className={`staking-style ${showHeadBg ? "show-head-bg" : ""}`}>
      <Header scrollToAnchor={scrollToAnchor} />
      <div className="staking">
        <div className="head">
          <div className="pool">
            <div className='title'>Pool</div>
            <div className='tips'>Seamless interchange between <img src={Solana} alt="" /><span>solana</span> ecology and  <img src={bitcoin} alt="" />  <span>bitcoin</span> ecology</div>
            <div className="stake-earn">Stake <b>$WORM</b> to earn new tokens. <br />Rewards are calculated per day.</div>
          </div>
          <div className="rotateImg"><img src={rotateImg} alt="" /></div>
        </div>
        <WalletNotConnected />
        <TabComponent />
      </div>
    </div>

  )
}

export default Staking